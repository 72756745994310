@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,700;1,100;1,200;1,400;1,500;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap";
.s-alert-box, .s-alert-box * {
  box-sizing: border-box;
}

.s-alert-box {
  z-index: 1000;
  pointer-events: none;
  color: rgba(250, 251, 255, .95);
  max-width: 300px;
  background: rgba(42, 45, 50, .85);
  padding: 22px;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  font-size: 100%;
  line-height: 1.4;
  transition: top .4s, bottom .4s;
  position: fixed;
}

.s-alert-box.s-alert-show {
  pointer-events: auto;
}

.s-alert-box a {
  color: inherit;
  opacity: .7;
  font-weight: 700;
}

.s-alert-box a:hover, .s-alert-box a:focus {
  opacity: 1;
}

.s-alert-box p {
  margin: 0;
}

.s-alert-box.s-alert-show, .s-alert-box.s-alert-visible {
  pointer-events: auto;
}

.s-alert-close {
  width: 20px;
  height: 20px;
  text-indent: 100%;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 4px;
  right: 4px;
  overflow: hidden;
}

.s-alert-close:hover, .s-alert-close:focus {
  outline: none;
}

.s-alert-close:before, .s-alert-close:after {
  content: "";
  width: 3px;
  height: 60%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
}

.s-alert-close:hover:before, .s-alert-close:hover:after {
  background: #fff;
}

.s-alert-close:before {
  transform: translate(-50%, -50%)rotate(45deg);
}

.s-alert-close:after {
  transform: translate(-50%, -50%)rotate(-45deg);
}

.s-alert-bottom-left {
  top: auto;
  bottom: 30px;
  left: 30px;
  right: auto;
}

.s-alert-top-left {
  top: 30px;
  bottom: auto;
  left: 30px;
  right: auto;
}

.s-alert-top-right {
  top: 30px;
  bottom: auto;
  left: auto;
  right: 30px;
}

.s-alert-bottom-right {
  top: auto;
  bottom: 30px;
  left: auto;
  right: 30px;
}

.s-alert-bottom {
  width: 100%;
  max-width: 100%;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
}

.s-alert-top {
  width: 100%;
  max-width: 100%;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
}

.s-alert-info {
  color: #fff;
  background: #00a2d3;
}

.s-alert-success {
  color: #fff;
  background: #27ae60;
}

.s-alert-warning {
  color: #fff;
  background: #f1c40f;
}

.s-alert-error {
  color: #fff;
  background: #e74c3c;
}

[class^="s-alert-effect-"].s-alert-hide, [class*=" s-alert-effect-"].s-alert-hide {
  animation-direction: reverse;
}

.s-alert-box-height {
  visibility: hidden;
  position: fixed;
}

.s-alert-effect-slide .s-alert-close:before, .s-alert-effect-slide .s-alert-close:after, .s-alert-effect-slide .s-alert-close:hover:before, .s-alert-effect-slide .s-alert-close:hover:after {
  background: #fff;
}

.s-alert-top-left.s-alert-effect-slide.s-alert-show, .s-alert-bottom-left.s-alert-effect-slide.s-alert-show, .s-alert-top.s-alert-effect-slide.s-alert-show, .s-alert-bottom.s-alert-effect-slide.s-alert-show {
  animation-name: animSlideElastic;
  animation-duration: 1s;
  animation-timing-function: linear;
}

@keyframes animSlideElastic {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -1000, 0, 0, 1);
  }

  1.66667% {
    transform: matrix3d(1.92933, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -739.268, 0, 0, 1);
  }

  3.33333% {
    transform: matrix3d(1.96989, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -521.825, 0, 0, 1);
  }

  5% {
    transform: matrix3d(1.70901, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -349.261, 0, 0, 1);
  }

  6.66667% {
    transform: matrix3d(1.4235, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -218.324, 0, 0, 1);
  }

  8.33333% {
    transform: matrix3d(1.21065, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -123.298, 0, 0, 1);
  }

  10% {
    transform: matrix3d(1.08167, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -57.5927, 0, 0, 1);
  }

  11.6667% {
    transform: matrix3d(1.0165, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -14.7237, 0, 0, 1);
  }

  13.3333% {
    transform: matrix3d(.99057, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 11.1279, 0, 0, 1);
  }

  15% {
    transform: matrix3d(.98478, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 24.8634, 0, 0, 1);
  }

  16.6667% {
    transform: matrix3d(.98719, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 30.405, 0, 0, 1);
  }

  18.3333% {
    transform: matrix3d(.9916, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 30.7528, 0, 0, 1);
  }

  20% {
    transform: matrix3d(.99541, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 28.1014, 0, 0, 1);
  }

  21.6667% {
    transform: matrix3d(.99795, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 23.9827, 0, 0, 1);
  }

  23.3333% {
    transform: matrix3d(.99936, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 19.4075, 0, 0, 1);
  }

  25% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 14.9956, 0, 0, 1);
  }

  26.6667% {
    transform: matrix3d(1.00021, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 11.0858, 0, 0, 1);
  }

  28.3333% {
    transform: matrix3d(1.00022, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 7.82507, 0, 0, 1);
  }

  30% {
    transform: matrix3d(1.00016, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 5.23737, 0, 0, 1);
  }

  31.6667% {
    transform: matrix3d(1.0001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 3.27389, 0, 0, 1);
  }

  33.3333% {
    transform: matrix3d(1.00005, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1.84893, 0, 0, 1);
  }

  35% {
    transform: matrix3d(1.00002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .86364, 0, 0, 1);
  }

  36.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .22079, 0, 0, 1);
  }

  38.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.16687, 0, 0, 1);
  }

  40% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.37284, 0, 0, 1);
  }

  41.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.45594, 0, 0, 1);
  }

  43.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.46116, 0, 0, 1);
  }

  45% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.4214, 0, 0, 1);
  }

  46.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.35963, 0, 0, 1);
  }

  48.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.29103, 0, 0, 1);
  }

  50% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.22487, 0, 0, 1);
  }

  51.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.16624, 0, 0, 1);
  }

  53.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.11734, 0, 0, 1);
  }

  55% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.07854, 0, 0, 1);
  }

  56.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.04909, 0, 0, 1);
  }

  58.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.02773, 0, 0, 1);
  }

  60% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.01295, 0, 0, 1);
  }

  61.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.00331, 0, 0, 1);
  }

  63.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .0025, 0, 0, 1);
  }

  65% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .00559, 0, 0, 1);
  }

  66.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .00684, 0, 0, 1);
  }

  68.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .00692, 0, 0, 1);
  }

  70% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .00632, 0, 0, 1);
  }

  71.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .00539, 0, 0, 1);
  }

  73.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .00436, 0, 0, 1);
  }

  75% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .00337, 0, 0, 1);
  }

  76.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .00249, 0, 0, 1);
  }

  78.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .00176, 0, 0, 1);
  }

  80% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .00118, 0, 0, 1);
  }

  81.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .00074, 0, 0, 1);
  }

  83.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .00042, 0, 0, 1);
  }

  85% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .00019, 0, 0, 1);
  }

  86.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .00005, 0, 0, 1);
  }

  88.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.00004, 0, 0, 1);
  }

  90% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.00008, 0, 0, 1);
  }

  91.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.0001, 0, 0, 1);
  }

  93.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.0001, 0, 0, 1);
  }

  95% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.00009, 0, 0, 1);
  }

  96.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.00008, 0, 0, 1);
  }

  98.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.00007, 0, 0, 1);
  }

  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

.s-alert-top-right.s-alert-effect-slide.s-alert-show, .s-alert-bottom-right.s-alert-effect-slide.s-alert-show {
  animation-name: animSlideElasticRight;
  animation-duration: 1s;
  animation-timing-function: linear;
}

@keyframes animSlideElasticRight {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1000, 0, 0, 1);
  }

  3.33333% {
    transform: matrix3d(1.96989, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 521.825, 0, 0, 1);
  }

  6.66667% {
    transform: matrix3d(1.4235, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 218.324, 0, 0, 1);
  }

  10% {
    transform: matrix3d(1.08167, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 57.5927, 0, 0, 1);
  }

  13.3333% {
    transform: matrix3d(.99057, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -11.1279, 0, 0, 1);
  }

  16.6667% {
    transform: matrix3d(.98719, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -30.405, 0, 0, 1);
  }

  20% {
    transform: matrix3d(.99541, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -28.1014, 0, 0, 1);
  }

  23.3333% {
    transform: matrix3d(.99936, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -19.4075, 0, 0, 1);
  }

  26.6667% {
    transform: matrix3d(1.00021, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -11.0858, 0, 0, 1);
  }

  30% {
    transform: matrix3d(1.00016, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -5.23737, 0, 0, 1);
  }

  33.3333% {
    transform: matrix3d(1.00005, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -1.84893, 0, 0, 1);
  }

  36.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.22079, 0, 0, 1);
  }

  40% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .37284, 0, 0, 1);
  }

  43.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .46116, 0, 0, 1);
  }

  46.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .35963, 0, 0, 1);
  }

  50% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .22487, 0, 0, 1);
  }

  53.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .11734, 0, 0, 1);
  }

  56.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .04909, 0, 0, 1);
  }

  60% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .01295, 0, 0, 1);
  }

  63.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.0025, 0, 0, 1);
  }

  66.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.00684, 0, 0, 1);
  }

  70% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.00632, 0, 0, 1);
  }

  73.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.00436, 0, 0, 1);
  }

  76.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.00249, 0, 0, 1);
  }

  80% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.00118, 0, 0, 1);
  }

  83.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.00042, 0, 0, 1);
  }

  86.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -.00005, 0, 0, 1);
  }

  90% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .00008, 0, 0, 1);
  }

  93.3333% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .0001, 0, 0, 1);
  }

  96.6667% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, .00008, 0, 0, 1);
  }

  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

.s-alert-top-left.s-alert-effect-slide.s-alert-hide, .s-alert-bottom-left.s-alert-effect-slide.s-alert-hide, .s-alert-top.s-alert-effect-slide.s-alert-hide, .s-alert-bottom.s-alert-effect-slide.s-alert-hide {
  animation-name: animSlide;
  animation-duration: .25s;
}

@-webkit-keyframes animSlide {
  0% {
    -webkit-transform: translate3d(-30px, 0, 0)translate3d(-100%, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@keyframes animSlide {
  0% {
    transform: translate3d(-30px, 0, 0)translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.s-alert-top-right.s-alert-effect-slide.s-alert-hide, .s-alert-bottom-right.s-alert-effect-slide.s-alert-hide, .s-alert-top-right.s-alert-effect-slide.s-alert-hide, .s-alert-bottom-right.s-alert-effect-slide.s-alert-hide {
  animation-name: animSlideRight;
  animation-duration: .25s;
}

@-webkit-keyframes animSlideRight {
  0% {
    -webkit-transform: translate3d(30px, 0, 0)translate3d(100%, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@keyframes animSlideRight {
  0% {
    transform: translate3d(30px, 0, 0)translate3d(100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.Toastify__toast-container {
  z-index: 9999;
  width: 320px;
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
  -webkit-transform: translate3d(0, 0, 9999px);
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: 64px;
  box-sizing: border-box;
  max-height: 800px;
  cursor: pointer;
  direction: ltr;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  font-family: sans-serif;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px rgba(0, 0, 0, .1), 0 2px 15px rgba(0, 0, 0, .05);
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--dark {
  color: #fff;
  background: #121212;
}

.Toastify__toast--default {
  color: #aaa;
  background: #fff;
}

.Toastify__toast--info {
  background: #3498db;
}

.Toastify__toast--success {
  background: #07bc0c;
}

.Toastify__toast--warning {
  background: #f1c40f;
}

.Toastify__toast--error {
  background: #e74c3c;
}

.Toastify__toast-body {
  flex: auto;
  margin: auto 0;
  padding: 6px;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--default {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: .7;
  transform-origin: 0;
  background-color: rgba(255, 255, 255, .7);
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear forwards;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}

.Toastify__progress-bar--dark {
  background: #bb86fc;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

.rc-tooltip.rc-tooltip-zoom-appear, .rc-tooltip.rc-tooltip-zoom-enter {
  opacity: 0;
}

.rc-tooltip.rc-tooltip-zoom-enter, .rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}

.rc-tooltip-zoom-enter, .rc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: .3s;
  animation-timing-function: cubic-bezier(.18, .89, .32, 1.28);
  animation-play-state: paused;
  animation-fill-mode: both;
}

.rc-tooltip-zoom-leave {
  animation-duration: .3s;
  animation-timing-function: cubic-bezier(.6, -.3, .74, .05);
  animation-play-state: paused;
  animation-fill-mode: both;
}

.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active, .rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}

.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}

@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50%;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform-origin: 50%;
    transform: scale(1);
  }
}

@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50%;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform-origin: 50%;
    transform: scale(0);
  }
}

.rc-tooltip {
  z-index: 1070;
  visibility: visible;
  opacity: .9;
  font-size: 12px;
  line-height: 1.5;
  display: block;
  position: absolute;
}

.rc-tooltip-hidden {
  display: none;
}

.rc-tooltip-placement-top, .rc-tooltip-placement-topLeft, .rc-tooltip-placement-topRight {
  padding: 5px 0 9px;
}

.rc-tooltip-placement-right, .rc-tooltip-placement-rightTop, .rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}

.rc-tooltip-placement-bottom, .rc-tooltip-placement-bottomLeft, .rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px;
}

.rc-tooltip-placement-left, .rc-tooltip-placement-leftTop, .rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}

.rc-tooltip-inner {
  color: #fff;
  text-align: left;
  min-height: 34px;
  background-color: #373737;
  border-radius: 6px;
  padding: 8px 10px;
  text-decoration: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, .17);
}

.rc-tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  position: absolute;
}

.rc-tooltip-placement-top .rc-tooltip-arrow, .rc-tooltip-placement-topLeft .rc-tooltip-arrow, .rc-tooltip-placement-topRight .rc-tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #373737;
  margin-left: -5px;
  bottom: 4px;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}

.rc-tooltip-placement-right .rc-tooltip-arrow, .rc-tooltip-placement-rightTop .rc-tooltip-arrow, .rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-right-color: #373737;
  margin-top: -5px;
  left: 4px;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  margin-top: 0;
  top: 15%;
}

.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-left .rc-tooltip-arrow, .rc-tooltip-placement-leftTop .rc-tooltip-arrow, .rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-left-color: #373737;
  margin-top: -5px;
  right: 4px;
}

.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  margin-top: 0;
  top: 15%;
}

.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow, .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow, .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #373737;
  margin-left: -5px;
  top: 4px;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}

.ReactTable {
  border: 1px solid rgba(0, 0, 0, .1);
  flex-direction: column;
  display: flex;
  position: relative;
}

.ReactTable * {
  box-sizing: border-box;
}

.ReactTable .rt-table {
  width: 100%;
  border-collapse: collapse;
  flex-direction: column;
  flex: auto;
  align-items: stretch;
  display: flex;
  overflow: auto;
}

.ReactTable .rt-thead {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-direction: column;
  flex: 1 0 auto;
  display: flex;
}

.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, .03);
  border-bottom: 1px solid rgba(0, 0, 0, .05);
}

.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, .05);
}

.ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
  font-size: inherit;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 3px;
  outline: none;
  padding: 5px 7px;
  font-weight: normal;
}

.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0, 0, 0, .02);
}

.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px rgba(0, 0, 0, .15);
}

.ReactTable .rt-thead .rt-tr {
  text-align: center;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  border-right: 1px solid rgba(0, 0, 0, .05);
  padding: 5px;
  line-height: normal;
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  position: relative;
  box-shadow: inset 0 0 rgba(0, 0, 0, 0);
}

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px rgba(0, 0, 0, .6);
}

.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px rgba(0, 0, 0, .6);
}

.ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}

.ReactTable .rt-thead .rt-th:last-child, .ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}

.ReactTable .rt-thead .rt-header-pivot:after, .ReactTable .rt-thead .rt-header-pivot:before {
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  border: solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 50%;
  left: 100%;
}

.ReactTable .rt-thead .rt-header-pivot:after {
  border-width: 8px;
  border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #fff;
  margin-top: -8px;
}

.ReactTable .rt-thead .rt-header-pivot:before {
  border-width: 10px;
  border-color: rgba(102, 102, 102, 0) rgba(102, 102, 102, 0) rgba(102, 102, 102, 0) #f7f7f7;
  margin-top: -10px;
}

.ReactTable .rt-tbody {
  flex-direction: column;
  flex: 99999 auto;
  display: flex;
  overflow: auto;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid rgba(0, 0, 0, .05);
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, .02);
}

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}

.ReactTable .rt-tr-group {
  flex-direction: column;
  flex: 1 0 auto;
  align-items: stretch;
  display: flex;
}

.ReactTable .rt-tr {
  flex: 1 0 auto;
  display: inline-flex;
}

.ReactTable .rt-th, .ReactTable .rt-td {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 0;
  padding: 7px 5px;
  transition: width .3s, min-width .3s, padding .3s, opacity .3s;
  overflow: hidden;
}

.ReactTable .rt-th.-hidden, .ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  opacity: 0 !important;
  border: 0 !important;
  padding: 0 !important;
}

.ReactTable .rt-expander {
  color: rgba(0, 0, 0, 0);
  margin: 0 10px;
  display: inline-block;
  position: relative;
}

.ReactTable .rt-expander:after {
  content: "";
  width: 0;
  height: 0;
  cursor: pointer;
  border-top: 7px solid rgba(0, 0, 0, .8);
  border-left: 5.04px solid rgba(0, 0, 0, 0);
  border-right: 5.04px solid rgba(0, 0, 0, 0);
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-90deg);
}

.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
}

.ReactTable .rt-resizer {
  width: 36px;
  cursor: col-resize;
  z-index: 10;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -18px;
}

.ReactTable .rt-tfoot {
  flex-direction: column;
  flex: 1 0 auto;
  display: flex;
  box-shadow: 0 0 15px rgba(0, 0, 0, .15);
}

.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, .05);
}

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}

.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, .03);
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, .05);
}

.ReactTable .-pagination {
  z-index: 1;
  border-top: 2px solid rgba(0, 0, 0, .1);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  padding: 3px;
  display: flex;
  box-shadow: 0 0 15px rgba(0, 0, 0, .1);
}

.ReactTable .-pagination input, .ReactTable .-pagination select {
  font-size: inherit;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 3px;
  outline: none;
  padding: 5px 7px;
  font-weight: normal;
}

.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, .6);
  cursor: pointer;
  background: rgba(0, 0, 0, .1);
  border: 0;
  border-radius: 3px;
  outline: none;
  padding: 6px;
  font-size: 1em;
  transition: all .1s;
  display: block;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: .5;
  cursor: default;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  color: #fff;
  background: rgba(0, 0, 0, .3);
}

.ReactTable .-pagination .-previous, .ReactTable .-pagination .-next {
  text-align: center;
  flex: 1;
}

.ReactTable .-pagination .-center {
  text-align: center;
  flex-flow: wrap;
  flex: 1.5;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.ReactTable .-pagination .-pageInfo {
  white-space: nowrap;
  margin: 3px 10px;
  display: inline-block;
}

.ReactTable .-pagination .-pageJump {
  display: inline-block;
}

.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}

.ReactTable .rt-noData {
  z-index: 1;
  pointer-events: none;
  color: rgba(0, 0, 0, .5);
  background: rgba(255, 255, 255, .8);
  padding: 20px;
  transition: all .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ReactTable .-loading {
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  background: rgba(255, 255, 255, .8);
  transition: all .3s;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ReactTable .-loading > div {
  text-align: center;
  width: 100%;
  color: rgba(0, 0, 0, .6);
  font-size: 15px;
  transition: all .3s cubic-bezier(.25, .46, .45, .94);
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-52%);
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active > div {
  transform: translateY(50%);
}

.ReactTable .rt-resizing .rt-th, .ReactTable .rt-resizing .rt-td {
  cursor: col-resize;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: none !important;
}

.ql-container {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  position: relative;
}

.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
}

.ql-container.ql-disabled .ql-editor ul[data-checked] > li:before {
  pointer-events: none;
}

.ql-clipboard {
  height: 1px;
  position: absolute;
  top: 50%;
  left: -100000px;
  overflow-y: hidden;
}

.ql-clipboard p {
  margin: 0;
  padding: 0;
}

.ql-editor {
  box-sizing: border-box;
  height: 100%;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  padding: 12px 15px;
  line-height: 1.42;
  overflow-y: auto;
}

.ql-editor > * {
  cursor: text;
}

.ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  margin: 0;
  padding: 0;
}

.ql-editor ol, .ql-editor ul {
  padding-left: 1.5em;
}

.ql-editor ol > li, .ql-editor ul > li {
  list-style-type: none;
}

.ql-editor ul > li:before {
  content: "•";
}

.ql-editor ul[data-checked="true"], .ql-editor ul[data-checked="false"] {
  pointer-events: none;
}

.ql-editor ul[data-checked="true"] > li *, .ql-editor ul[data-checked="false"] > li * {
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li:before, .ql-editor ul[data-checked="false"] > li:before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li:before {
  content: "☑";
}

.ql-editor ul[data-checked="false"] > li:before {
  content: "☐";
}

.ql-editor li:before {
  white-space: nowrap;
  width: 1.2em;
  display: inline-block;
}

.ql-editor li:not(.ql-direction-rtl):before {
  text-align: right;
  margin-left: -1.5em;
  margin-right: .3em;
}

.ql-editor li.ql-direction-rtl:before {
  margin-left: .3em;
  margin-right: -1.5em;
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}

.ql-editor ol li.ql-direction-rtl, .ql-editor ul li.ql-direction-rtl {
  padding-right: 1.5em;
}

.ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}

.ql-editor ol li:before {
  content: counter(list-0, decimal) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
}

.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
}

.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
}

.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}

.ql-editor ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
}

.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
}

.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
}

.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}

.ql-editor ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
}

.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}

.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
}

.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-8 {
  counter-reset: list-9;
}

.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}

.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) ". ";
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}

.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}

.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}

.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}

.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}

.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}

.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}

.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}

.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}

.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}

.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}

.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}

.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}

.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}

.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}

.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}

.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}

.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}

.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}

.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}

.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}

.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}

.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}

.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}

.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}

.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}

.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}

.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}

.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}

.ql-editor .ql-video {
  max-width: 100%;
  display: block;
}

.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}

.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}

.ql-editor .ql-bg-black {
  background-color: #000;
}

.ql-editor .ql-bg-red {
  background-color: #e60000;
}

.ql-editor .ql-bg-orange {
  background-color: #f90;
}

.ql-editor .ql-bg-yellow {
  background-color: #ff0;
}

.ql-editor .ql-bg-green {
  background-color: #008a00;
}

.ql-editor .ql-bg-blue {
  background-color: #06c;
}

.ql-editor .ql-bg-purple {
  background-color: #93f;
}

.ql-editor .ql-color-white {
  color: #fff;
}

.ql-editor .ql-color-red {
  color: #e60000;
}

.ql-editor .ql-color-orange {
  color: #f90;
}

.ql-editor .ql-color-yellow {
  color: #ff0;
}

.ql-editor .ql-color-green {
  color: #008a00;
}

.ql-editor .ql-color-blue {
  color: #06c;
}

.ql-editor .ql-color-purple {
  color: #93f;
}

.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}

.ql-editor .ql-size-small {
  font-size: .75em;
}

.ql-editor .ql-size-large {
  font-size: 1.5em;
}

.ql-editor .ql-size-huge {
  font-size: 2.5em;
}

.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}

.ql-editor .ql-align-center {
  text-align: center;
}

.ql-editor .ql-align-justify {
  text-align: justify;
}

.ql-editor .ql-align-right {
  text-align: right;
}

.ql-editor.ql-blank:before {
  color: rgba(0, 0, 0, .6);
  content: attr(data-placeholder);
  pointer-events: none;
  font-style: italic;
  position: absolute;
  left: 15px;
  right: 15px;
}

.ql-snow.ql-toolbar:after, .ql-snow .ql-toolbar:after {
  clear: both;
  content: "";
  display: table;
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
  cursor: pointer;
  float: left;
  height: 24px;
  width: 28px;
  background: none;
  border: none;
  padding: 3px 5px;
  display: inline-block;
}

.ql-snow.ql-toolbar button svg, .ql-snow .ql-toolbar button svg {
  float: left;
  height: 100%;
}

.ql-snow.ql-toolbar button:active:hover, .ql-snow .ql-toolbar button:active:hover {
  outline: none;
}

.ql-snow.ql-toolbar input.ql-image[type="file"], .ql-snow .ql-toolbar input.ql-image[type="file"] {
  display: none;
}

.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #06c;
}

.ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #06c;
}

.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #06c;
}

@media (pointer: coarse) {
  .ql-snow.ql-toolbar button:hover:not(.ql-active), .ql-snow .ql-toolbar button:hover:not(.ql-active) {
    color: #444;
  }

  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
    fill: #444;
  }

  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
    stroke: #444;
  }
}

.ql-snow, .ql-snow * {
  box-sizing: border-box;
}

.ql-snow .ql-hidden {
  display: none;
}

.ql-snow .ql-out-bottom, .ql-snow .ql-out-top {
  visibility: hidden;
}

.ql-snow .ql-tooltip {
  position: absolute;
  transform: translateY(10px);
}

.ql-snow .ql-tooltip a {
  cursor: pointer;
  text-decoration: none;
}

.ql-snow .ql-tooltip.ql-flip {
  transform: translateY(-10px);
}

.ql-snow .ql-formats {
  vertical-align: middle;
  display: inline-block;
}

.ql-snow .ql-formats:after {
  clear: both;
  content: "";
  display: table;
}

.ql-snow .ql-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}

.ql-snow .ql-stroke-miter {
  fill: none;
  stroke: #444;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
  fill: #444;
}

.ql-snow .ql-empty {
  fill: none;
}

.ql-snow .ql-even {
  fill-rule: evenodd;
}

.ql-snow .ql-thin, .ql-snow .ql-stroke.ql-thin {
  stroke-width: 1px;
}

.ql-snow .ql-transparent {
  opacity: .4;
}

.ql-snow .ql-direction svg:last-child {
  display: none;
}

.ql-snow .ql-direction.ql-active svg:last-child {
  display: inline;
}

.ql-snow .ql-direction.ql-active svg:first-child {
  display: none;
}

.ql-snow .ql-editor h1 {
  font-size: 2em;
}

.ql-snow .ql-editor h2 {
  font-size: 1.5em;
}

.ql-snow .ql-editor h3 {
  font-size: 1.17em;
}

.ql-snow .ql-editor h4 {
  font-size: 1em;
}

.ql-snow .ql-editor h5 {
  font-size: .83em;
}

.ql-snow .ql-editor h6 {
  font-size: .67em;
}

.ql-snow .ql-editor a {
  text-decoration: underline;
}

.ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 16px;
}

.ql-snow .ql-editor code, .ql-snow .ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px;
}

.ql-snow .ql-editor pre {
  white-space: pre-wrap;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
}

.ql-snow .ql-editor code {
  padding: 2px 4px;
  font-size: 85%;
}

.ql-snow .ql-editor pre.ql-syntax {
  color: #f8f8f2;
  background-color: #23241f;
  overflow: visible;
}

.ql-snow .ql-editor img {
  max-width: 100%;
}

.ql-snow .ql-picker {
  color: #444;
  float: left;
  height: 24px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

.ql-snow .ql-picker-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding-left: 8px;
  padding-right: 2px;
  display: inline-block;
  position: relative;
}

.ql-snow .ql-picker-label:before {
  line-height: 22px;
  display: inline-block;
}

.ql-snow .ql-picker-options {
  min-width: 100%;
  white-space: nowrap;
  background-color: #fff;
  padding: 4px 8px;
  display: none;
  position: absolute;
}

.ql-snow .ql-picker-options .ql-picker-item {
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #ccc;
  z-index: 2;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #ccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #ccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  z-index: 1;
  margin-top: -1px;
  display: block;
  top: 100%;
}

.ql-snow .ql-color-picker, .ql-snow .ql-icon-picker {
  width: 28px;
}

.ql-snow .ql-color-picker .ql-picker-label, .ql-snow .ql-icon-picker .ql-picker-label {
  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-label svg, .ql-snow .ql-icon-picker .ql-picker-label svg {
  right: 4px;
}

.ql-snow .ql-icon-picker .ql-picker-options {
  padding: 4px 0;
}

.ql-snow .ql-icon-picker .ql-picker-item {
  height: 24px;
  width: 24px;
  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-options {
  width: 152px;
  padding: 3px 5px;
}

.ql-snow .ql-color-picker .ql-picker-item {
  float: left;
  height: 16px;
  width: 16px;
  border: 1px solid rgba(0, 0, 0, 0);
  margin: 2px;
  padding: 0;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  width: 18px;
  margin-top: -9px;
  position: absolute;
  top: 50%;
  right: 0;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=""]):before {
  content: attr(data-label);
}

.ql-snow .ql-picker.ql-header {
  width: 98px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label:before, .ql-snow .ql-picker.ql-header .ql-picker-item:before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before {
  content: "Heading 1";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before {
  content: "Heading 2";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before {
  content: "Heading 3";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before {
  content: "Heading 4";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before {
  content: "Heading 5";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before {
  content: "Heading 6";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before {
  font-size: 2em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before {
  font-size: 1.5em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before {
  font-size: 1.17em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before {
  font-size: 1em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before {
  font-size: .83em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before {
  font-size: .67em;
}

.ql-snow .ql-picker.ql-font {
  width: 108px;
}

.ql-snow .ql-picker.ql-font .ql-picker-label:before, .ql-snow .ql-picker.ql-font .ql-picker-item:before {
  content: "Sans Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]:before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]:before {
  content: "Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]:before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]:before {
  content: "Monospace";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]:before {
  font-family: Georgia, Times New Roman, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]:before {
  font-family: Monaco, Courier New, monospace;
}

.ql-snow .ql-picker.ql-size {
  width: 98px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label:before, .ql-snow .ql-picker.ql-size .ql-picker-item:before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]:before {
  content: "Small";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]:before {
  content: "Large";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]:before {
  content: "Huge";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]:before {
  font-size: 10px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]:before {
  font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]:before {
  font-size: 32px;
}

.ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background-color: #fff;
}

.ql-snow .ql-color-picker.ql-color .ql-picker-item {
  background-color: #000;
}

.ql-toolbar.ql-snow {
  box-sizing: border-box;
  border: 1px solid #ccc;
  padding: 8px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
}

.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid rgba(0, 0, 0, 0);
}

.ql-toolbar.ql-snow .ql-picker-options {
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: 0 2px 8px rgba(0, 0, 0, .2);
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label, .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #ccc;
}

.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected, .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
  border-color: #000;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0;
}

.ql-snow .ql-tooltip {
  color: #444;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 12px;
  box-shadow: 0 0 5px #ddd;
}

.ql-snow .ql-tooltip:before {
  content: "Visit URL:";
  margin-right: 8px;
  line-height: 26px;
}

.ql-snow .ql-tooltip input[type="text"] {
  height: 26px;
  width: 170px;
  border: 1px solid #ccc;
  margin: 0;
  padding: 3px 5px;
  font-size: 13px;
  display: none;
}

.ql-snow .ql-tooltip a.ql-preview {
  max-width: 200px;
  text-overflow: ellipsis;
  vertical-align: top;
  display: inline-block;
  overflow-x: hidden;
}

.ql-snow .ql-tooltip a.ql-action:after {
  content: "Edit";
  border-right: 1px solid #ccc;
  margin-left: 16px;
  padding-right: 8px;
}

.ql-snow .ql-tooltip a.ql-remove:before {
  content: "Remove";
  margin-left: 8px;
}

.ql-snow .ql-tooltip a {
  line-height: 26px;
}

.ql-snow .ql-tooltip.ql-editing a.ql-preview, .ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}

.ql-snow .ql-tooltip.ql-editing input[type="text"] {
  display: inline-block;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action:after {
  content: "Save";
  border-right: 0;
  padding-right: 0;
}

.ql-snow .ql-tooltip[data-mode="link"]:before {
  content: "Enter link:";
}

.ql-snow .ql-tooltip[data-mode="formula"]:before {
  content: "Enter formula:";
}

.ql-snow .ql-tooltip[data-mode="video"]:before {
  content: "Enter video:";
}

.ql-snow a {
  color: #06c;
}

.ql-container.ql-snow {
  border: 1px solid #ccc;
}

.rdw-option-wrapper {
  min-width: 25px;
  height: 20px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  padding: 5px;
  display: flex;
}

.rdw-option-wrapper:hover {
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-option-wrapper:active, .rdw-option-active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-option-disabled {
  opacity: .3;
  cursor: default;
}

.rdw-dropdown-wrapper {
  height: 30px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 3px;
}

.rdw-dropdown-wrapper:focus {
  outline: none;
}

.rdw-dropdown-wrapper:hover {
  background-color: #fff;
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-dropdown-wrapper:active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-dropdown-carettoopen {
  height: 0;
  width: 0;
  border-top: 6px solid #000;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 35%;
  right: 10%;
}

.rdw-dropdown-carettoclose {
  height: 0;
  width: 0;
  border-bottom: 6px solid #000;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 35%;
  right: 10%;
}

.rdw-dropdown-selectedtext {
  height: 100%;
  align-items: center;
  padding: 0 5px;
  display: flex;
  position: relative;
}

.rdw-dropdown-optionwrapper {
  z-index: 100;
  width: 98%;
  max-height: 250px;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-y: scroll;
}

.rdw-dropdown-optionwrapper:hover {
  background-color: #fff;
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-dropdownoption-default {
  min-height: 25px;
  align-items: center;
  padding: 0 5px;
  display: flex;
}

.rdw-dropdownoption-highlighted {
  background: #f1f1f1;
}

.rdw-dropdownoption-active {
  background: #f5f5f5;
}

.rdw-dropdownoption-disabled {
  opacity: .3;
  cursor: default;
}

.rdw-inline-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-inline-dropdown {
  width: 50px;
}

.rdw-inline-dropdownoption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-block-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-block-dropdown {
  width: 110px;
}

.rdw-fontsize-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-fontsize-dropdown {
  min-width: 40px;
}

.rdw-fontsize-option {
  justify-content: center;
  display: flex;
}

.rdw-fontfamily-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-fontfamily-dropdown {
  width: 115px;
}

.rdw-fontfamily-placeholder {
  white-space: nowrap;
  max-width: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rdw-fontfamily-optionwrapper {
  width: 140px;
}

.rdw-list-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-list-dropdown {
  width: 50px;
  z-index: 90;
}

.rdw-list-dropdownOption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-text-align-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-text-align-dropdown {
  width: 50px;
  z-index: 90;
}

.rdw-text-align-dropdownOption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-right-aligned-block {
  text-align: right;
}

.rdw-left-aligned-block {
  text-align: left !important;
}

.rdw-center-aligned-block {
  text-align: center !important;
}

.rdw-justify-aligned-block {
  text-align: justify !important;
}

.rdw-right-aligned-block > div, .rdw-left-aligned-block > div, .rdw-center-aligned-block > div, .rdw-justify-aligned-block > div {
  display: inline-block;
}

.rdw-colorpicker-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-colorpicker-modal {
  width: 175px;
  height: 175px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-direction: column;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-colorpicker-modal-header {
  padding-bottom: 5px;
  display: flex;
}

.rdw-colorpicker-modal-style-label {
  width: 50%;
  text-align: center;
  cursor: pointer;
  padding: 0 10px 5px;
  font-size: 15px;
}

.rdw-colorpicker-modal-style-label-active {
  border-bottom: 2px solid #0a66b7;
}

.rdw-colorpicker-modal-options {
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  margin: 5px auto;
  display: flex;
  overflow: scroll;
}

.rdw-colorpicker-cube {
  width: 22px;
  height: 22px;
  border: 1px solid #f1f1f1;
}

.rdw-colorpicker-option {
  min-height: 20px;
  width: 22px;
  height: 22px;
  min-width: 22px;
  border: none;
  margin: 3px;
  padding: 0;
  box-shadow: inset 1px 2px 1px #bfbdbd;
}

.rdw-colorpicker-option:hover {
  box-shadow: 1px 2px 1px #bfbdbd;
}

.rdw-colorpicker-option:active {
  box-shadow: -1px -2px 1px #bfbdbd;
}

.rdw-colorpicker-option-active {
  box-shadow: 0 0 2px 2px #bfbdbd;
}

.rdw-link-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-link-dropdown {
  width: 50px;
}

.rdw-link-dropdownOption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-link-dropdownPlaceholder {
  margin-left: 8px;
}

.rdw-link-modal {
  width: 235px;
  height: 205px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-direction: column;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-link-modal-label {
  font-size: 15px;
}

.rdw-link-modal-input {
  height: 25px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 0 5px;
}

.rdw-link-modal-input:focus {
  outline: none;
}

.rdw-link-modal-buttonsection {
  margin: 0 auto;
}

.rdw-link-modal-target-option {
  margin-bottom: 20px;
}

.rdw-link-modal-target-option > span {
  margin-left: 5px;
}

.rdw-link-modal-btn {
  width: 75px;
  height: 30px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin-left: 10px;
}

.rdw-link-modal-btn:hover {
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-link-modal-btn:active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-link-modal-btn:focus {
  outline: none !important;
}

.rdw-link-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-link-dropdownoption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-history-dropdown {
  width: 50px;
}

.rdw-embedded-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-embedded-modal {
  width: 235px;
  height: 180px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-embedded-modal-header {
  font-size: 15px;
  display: flex;
}

.rdw-embedded-modal-header-option {
  width: 50%;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rdw-embedded-modal-header-label {
  width: 95px;
  background: #6eb8d4;
  border: 1px solid #f1f1f1;
  border-bottom: 2px solid #0a66b7;
  margin-top: 5px;
}

.rdw-embedded-modal-link-section {
  flex-direction: column;
  display: flex;
}

.rdw-embedded-modal-link-input {
  width: 88%;
  height: 35px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 10px 0;
  padding: 0 5px;
  font-size: 15px;
}

.rdw-embedded-modal-link-input-wrapper {
  align-items: center;
  display: flex;
}

.rdw-embedded-modal-link-input:focus {
  outline: none;
}

.rdw-embedded-modal-btn-section {
  justify-content: center;
  display: flex;
}

.rdw-embedded-modal-btn {
  width: 75px;
  height: 30px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 3px;
}

.rdw-embedded-modal-btn:hover {
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-embedded-modal-btn:active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-embedded-modal-btn:focus {
  outline: none !important;
}

.rdw-embedded-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-embedded-modal-size {
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  display: flex;
}

.rdw-embedded-modal-size-input {
  width: 80%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 12px;
}

.rdw-embedded-modal-size-input:focus {
  outline: none;
}

.rdw-emoji-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-emoji-modal {
  width: 235px;
  height: 180px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-wrap: wrap;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  overflow: auto;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-emoji-icon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 2.5px;
  font-size: 22px;
  display: flex;
}

.rdw-spinner {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rdw-spinner > div {
  width: 12px;
  height: 12px;
  background-color: #333;
  border-radius: 100%;
  animation: sk-bouncedelay 1.4s ease-in-out infinite both;
  display: inline-block;
}

.rdw-spinner .rdw-bounce1 {
  animation-delay: -.32s;
}

.rdw-spinner .rdw-bounce2 {
  animation-delay: -.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.rdw-image-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-image-modal {
  width: 235px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-direction: column;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-image-modal-header {
  margin: 10px 0;
  font-size: 15px;
  display: flex;
}

.rdw-image-modal-header-option {
  width: 50%;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rdw-image-modal-header-label {
  width: 80px;
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  margin-top: 5px;
}

.rdw-image-modal-header-label-highlighted {
  background: #6eb8d4;
  border-bottom: 2px solid #0a66b7;
}

.rdw-image-modal-upload-option {
  width: 100%;
  color: gray;
  cursor: pointer;
  outline-offset: -10px;
  background-color: #f1f1f1;
  border: none;
  outline: 2px dashed gray;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  padding: 9px 0;
  font-size: 15px;
  display: flex;
}

.rdw-image-modal-upload-option-highlighted {
  outline: 2px dashed #0a66b7;
}

.rdw-image-modal-upload-option-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: flex;
}

.rdw-image-modal-upload-option-label span {
  padding: 0 20px;
}

.rdw-image-modal-upload-option-image-preview {
  max-width: 100%;
  max-height: 200px;
}

.rdw-image-modal-upload-option-input {
  width: .1px;
  height: .1px;
  opacity: 0;
  z-index: -1;
  position: absolute;
  overflow: hidden;
}

.rdw-image-modal-url-section {
  align-items: center;
  display: flex;
}

.rdw-image-modal-url-input {
  width: 90%;
  height: 35px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 15px 0 12px;
  padding: 0 5px;
  font-size: 15px;
}

.rdw-image-modal-btn-section {
  margin: 10px auto 0;
}

.rdw-image-modal-url-input:focus {
  outline: none;
}

.rdw-image-modal-btn {
  width: 75px;
  height: 30px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 5px;
}

.rdw-image-modal-btn:hover {
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-image-modal-btn:active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-image-modal-btn:focus {
  outline: none !important;
}

.rdw-image-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-image-modal-spinner {
  width: 100%;
  height: 100%;
  opacity: .5;
  position: absolute;
  top: -3px;
  left: 0;
}

.rdw-image-modal-alt-input {
  width: 70%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin-left: 5px;
  font-size: 12px;
}

.rdw-image-modal-alt-input:focus {
  outline: none;
}

.rdw-image-modal-alt-lbl {
  font-size: 12px;
}

.rdw-image-modal-size {
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  display: flex;
}

.rdw-image-modal-size-input {
  width: 40%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 12px;
}

.rdw-image-modal-size-input:focus {
  outline: none;
}

.rdw-image-mandatory-sign {
  color: red;
  margin-left: 3px;
  margin-right: 3px;
}

.rdw-remove-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-history-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-history-dropdownoption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-history-dropdown {
  width: 50px;
}

.rdw-link-decorator-wrapper {
  position: relative;
}

.rdw-link-decorator-icon {
  cursor: pointer;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 40%;
}

.rdw-mention-link {
  color: #1236ff;
  background-color: #f0fbff;
  border-radius: 2px;
  padding: 1px 2px;
  text-decoration: none;
}

.rdw-suggestion-wrapper {
  position: relative;
}

.rdw-suggestion-dropdown {
  min-width: 100px;
  max-height: 150px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  flex-direction: column;
  display: flex;
  position: absolute;
  overflow: auto;
}

.rdw-suggestion-option {
  border-bottom: 1px solid #f1f1f1;
  padding: 7px 5px;
}

.rdw-suggestion-option-active {
  background-color: #f1f1f1;
}

.rdw-hashtag-link {
  color: #1236ff;
  background-color: #f0fbff;
  border-radius: 2px;
  padding: 1px 2px;
  text-decoration: none;
}

.rdw-image-alignment-options-popup {
  width: 105px;
  cursor: pointer;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  padding: 5px 2px;
  display: flex;
  position: absolute;
}

.rdw-alignment-option-left {
  justify-content: flex-start;
}

.rdw-image-alignment-option {
  height: 15px;
  width: 15px;
  min-width: 15px;
}

.rdw-image-alignment, .rdw-image-imagewrapper {
  position: relative;
}

.rdw-image-center {
  justify-content: center;
  display: flex;
}

.rdw-image-left {
  display: flex;
}

.rdw-image-right {
  justify-content: flex-end;
  display: flex;
}

.rdw-image-alignment-options-popup-right {
  right: 0;
}

.rdw-editor-main {
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.rdw-editor-toolbar {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 5px;
  padding: 6px 5px 0;
  font-size: 15px;
  display: flex;
}

.public-DraftStyleDefault-block {
  margin: 1em 0;
}

.rdw-editor-wrapper:focus {
  outline: none;
}

.rdw-editor-wrapper {
  box-sizing: content-box;
}

.rdw-editor-main blockquote {
  border-left: 5px solid #f1f1f1;
  padding-left: 5px;
}

.rdw-editor-main pre {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 1px 10px;
}

.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}

.public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: read-write-plaintext-only;
}

.DraftEditor-root {
  position: relative;
}

.DraftEditor-editorContainer {
  z-index: 1;
  background-color: rgba(255, 255, 255, 0);
  border-left: .1px solid rgba(0, 0, 0, 0);
  position: relative;
}

.public-DraftEditor-block {
  position: relative;
}

.DraftEditor-alignLeft .public-DraftStyleDefault-block {
  text-align: left;
}

.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
  text-align: left;
  left: 0;
}

.DraftEditor-alignCenter .public-DraftStyleDefault-block {
  text-align: center;
}

.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.DraftEditor-alignRight .public-DraftStyleDefault-block {
  text-align: right;
}

.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
  text-align: right;
  right: 0;
}

.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  z-index: 0;
  position: absolute;
}

.public-DraftEditorPlaceholder-hasFocus {
  color: #bdc1c9;
}

.DraftEditorPlaceholder-hidden {
  display: none;
}

.public-DraftStyleDefault-block {
  white-space: pre-wrap;
  position: relative;
}

.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}

.public-DraftStyleDefault-rtl {
  direction: rtl;
  text-align: right;
}

.public-DraftStyleDefault-listLTR {
  direction: ltr;
}

.public-DraftStyleDefault-listRTL {
  direction: rtl;
}

.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
  margin: 16px 0;
  padding: 0;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
  margin-right: 1.5em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
  margin-right: 3em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 4.5em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
  margin-right: 4.5em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 6em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
  margin-right: 6em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 7.5em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
  margin-right: 7.5em;
}

.public-DraftStyleDefault-unorderedListItem {
  list-style-type: square;
  position: relative;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: disc;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  list-style-type: circle;
}

.public-DraftStyleDefault-orderedListItem {
  list-style-type: none;
  position: relative;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  text-align: right;
  width: 30px;
  position: absolute;
  left: -36px;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
  text-align: left;
  width: 30px;
  position: absolute;
  right: -36px;
}

.public-DraftStyleDefault-orderedListItem:before {
  content: counter(ol0) ". ";
  counter-increment: ol0;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: counter(ol1) ". ";
  counter-increment: ol1;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: counter(ol2) ". ";
  counter-increment: ol2;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: counter(ol3) ". ";
  counter-increment: ol3;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: counter(ol4) ". ";
  counter-increment: ol4;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
  counter-reset: ol0;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
  counter-reset: ol1;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
  counter-reset: ol2;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
  counter-reset: ol3;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
  counter-reset: ol4;
}

.ql-container {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  position: relative;
}

.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
}

.ql-container.ql-disabled .ql-editor ul[data-checked] > li:before {
  pointer-events: none;
}

.ql-clipboard {
  height: 1px;
  position: absolute;
  top: 50%;
  left: -100000px;
  overflow-y: hidden;
}

.ql-clipboard p {
  margin: 0;
  padding: 0;
}

.ql-editor {
  box-sizing: border-box;
  height: 100%;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  padding: 12px 15px;
  line-height: 1.42;
  overflow-y: auto;
}

.ql-editor > * {
  cursor: text;
}

.ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  margin: 0;
  padding: 0;
}

.ql-editor ol, .ql-editor ul {
  padding-left: 1.5em;
}

.ql-editor ol > li, .ql-editor ul > li {
  list-style-type: none;
}

.ql-editor ul > li:before {
  content: "•";
}

.ql-editor ul[data-checked="true"], .ql-editor ul[data-checked="false"] {
  pointer-events: none;
}

.ql-editor ul[data-checked="true"] > li *, .ql-editor ul[data-checked="false"] > li * {
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li:before, .ql-editor ul[data-checked="false"] > li:before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li:before {
  content: "☑";
}

.ql-editor ul[data-checked="false"] > li:before {
  content: "☐";
}

.ql-editor li:before {
  white-space: nowrap;
  width: 1.2em;
  display: inline-block;
}

.ql-editor li:not(.ql-direction-rtl):before {
  text-align: right;
  margin-left: -1.5em;
  margin-right: .3em;
}

.ql-editor li.ql-direction-rtl:before {
  margin-left: .3em;
  margin-right: -1.5em;
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}

.ql-editor ol li.ql-direction-rtl, .ql-editor ul li.ql-direction-rtl {
  padding-right: 1.5em;
}

.ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}

.ql-editor ol li:before {
  content: counter(list-0, decimal) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
}

.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
}

.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
}

.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}

.ql-editor ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
}

.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
}

.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
}

.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}

.ql-editor ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
}

.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}

.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
}

.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-8 {
  counter-reset: list-9;
}

.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}

.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) ". ";
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}

.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}

.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}

.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}

.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}

.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}

.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}

.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}

.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}

.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}

.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}

.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}

.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}

.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}

.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}

.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}

.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}

.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}

.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}

.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}

.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}

.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}

.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}

.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}

.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}

.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}

.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}

.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}

.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}

.ql-editor .ql-video {
  max-width: 100%;
  display: block;
}

.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}

.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}

.ql-editor .ql-bg-black {
  background-color: #000;
}

.ql-editor .ql-bg-red {
  background-color: #e60000;
}

.ql-editor .ql-bg-orange {
  background-color: #f90;
}

.ql-editor .ql-bg-yellow {
  background-color: #ff0;
}

.ql-editor .ql-bg-green {
  background-color: #008a00;
}

.ql-editor .ql-bg-blue {
  background-color: #06c;
}

.ql-editor .ql-bg-purple {
  background-color: #93f;
}

.ql-editor .ql-color-white {
  color: #fff;
}

.ql-editor .ql-color-red {
  color: #e60000;
}

.ql-editor .ql-color-orange {
  color: #f90;
}

.ql-editor .ql-color-yellow {
  color: #ff0;
}

.ql-editor .ql-color-green {
  color: #008a00;
}

.ql-editor .ql-color-blue {
  color: #06c;
}

.ql-editor .ql-color-purple {
  color: #93f;
}

.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}

.ql-editor .ql-size-small {
  font-size: .75em;
}

.ql-editor .ql-size-large {
  font-size: 1.5em;
}

.ql-editor .ql-size-huge {
  font-size: 2.5em;
}

.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}

.ql-editor .ql-align-center {
  text-align: center;
}

.ql-editor .ql-align-justify {
  text-align: justify;
}

.ql-editor .ql-align-right {
  text-align: right;
}

.ql-editor.ql-blank:before {
  color: rgba(0, 0, 0, .6);
  content: attr(data-placeholder);
  pointer-events: none;
  font-style: italic;
  position: absolute;
  left: 15px;
  right: 15px;
}

.ql-snow.ql-toolbar:after, .ql-snow .ql-toolbar:after {
  clear: both;
  content: "";
  display: table;
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
  cursor: pointer;
  float: left;
  height: 24px;
  width: 28px;
  background: none;
  border: none;
  padding: 3px 5px;
  display: inline-block;
}

.ql-snow.ql-toolbar button svg, .ql-snow .ql-toolbar button svg {
  float: left;
  height: 100%;
}

.ql-snow.ql-toolbar button:active:hover, .ql-snow .ql-toolbar button:active:hover {
  outline: none;
}

.ql-snow.ql-toolbar input.ql-image[type="file"], .ql-snow .ql-toolbar input.ql-image[type="file"] {
  display: none;
}

.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #06c;
}

.ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #06c;
}

.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #06c;
}

@media (pointer: coarse) {
  .ql-snow.ql-toolbar button:hover:not(.ql-active), .ql-snow .ql-toolbar button:hover:not(.ql-active) {
    color: #444;
  }

  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
    fill: #444;
  }

  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
    stroke: #444;
  }
}

.ql-snow, .ql-snow * {
  box-sizing: border-box;
}

.ql-snow .ql-hidden {
  display: none;
}

.ql-snow .ql-out-bottom, .ql-snow .ql-out-top {
  visibility: hidden;
}

.ql-snow .ql-tooltip {
  position: absolute;
  transform: translateY(10px);
}

.ql-snow .ql-tooltip a {
  cursor: pointer;
  text-decoration: none;
}

.ql-snow .ql-tooltip.ql-flip {
  transform: translateY(-10px);
}

.ql-snow .ql-formats {
  vertical-align: middle;
  display: inline-block;
}

.ql-snow .ql-formats:after {
  clear: both;
  content: "";
  display: table;
}

.ql-snow .ql-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}

.ql-snow .ql-stroke-miter {
  fill: none;
  stroke: #444;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
  fill: #444;
}

.ql-snow .ql-empty {
  fill: none;
}

.ql-snow .ql-even {
  fill-rule: evenodd;
}

.ql-snow .ql-thin, .ql-snow .ql-stroke.ql-thin {
  stroke-width: 1px;
}

.ql-snow .ql-transparent {
  opacity: .4;
}

.ql-snow .ql-direction svg:last-child {
  display: none;
}

.ql-snow .ql-direction.ql-active svg:last-child {
  display: inline;
}

.ql-snow .ql-direction.ql-active svg:first-child {
  display: none;
}

.ql-snow .ql-editor h1 {
  font-size: 2em;
}

.ql-snow .ql-editor h2 {
  font-size: 1.5em;
}

.ql-snow .ql-editor h3 {
  font-size: 1.17em;
}

.ql-snow .ql-editor h4 {
  font-size: 1em;
}

.ql-snow .ql-editor h5 {
  font-size: .83em;
}

.ql-snow .ql-editor h6 {
  font-size: .67em;
}

.ql-snow .ql-editor a {
  text-decoration: underline;
}

.ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 16px;
}

.ql-snow .ql-editor code, .ql-snow .ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px;
}

.ql-snow .ql-editor pre {
  white-space: pre-wrap;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
}

.ql-snow .ql-editor code {
  padding: 2px 4px;
  font-size: 85%;
}

.ql-snow .ql-editor pre.ql-syntax {
  color: #f8f8f2;
  background-color: #23241f;
  overflow: visible;
}

.ql-snow .ql-editor img {
  max-width: 100%;
}

.ql-snow .ql-picker {
  color: #444;
  float: left;
  height: 24px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

.ql-snow .ql-picker-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding-left: 8px;
  padding-right: 2px;
  display: inline-block;
  position: relative;
}

.ql-snow .ql-picker-label:before {
  line-height: 22px;
  display: inline-block;
}

.ql-snow .ql-picker-options {
  min-width: 100%;
  white-space: nowrap;
  background-color: #fff;
  padding: 4px 8px;
  display: none;
  position: absolute;
}

.ql-snow .ql-picker-options .ql-picker-item {
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #ccc;
  z-index: 2;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #ccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #ccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  z-index: 1;
  margin-top: -1px;
  display: block;
  top: 100%;
}

.ql-snow .ql-color-picker, .ql-snow .ql-icon-picker {
  width: 28px;
}

.ql-snow .ql-color-picker .ql-picker-label, .ql-snow .ql-icon-picker .ql-picker-label {
  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-label svg, .ql-snow .ql-icon-picker .ql-picker-label svg {
  right: 4px;
}

.ql-snow .ql-icon-picker .ql-picker-options {
  padding: 4px 0;
}

.ql-snow .ql-icon-picker .ql-picker-item {
  height: 24px;
  width: 24px;
  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-options {
  width: 152px;
  padding: 3px 5px;
}

.ql-snow .ql-color-picker .ql-picker-item {
  float: left;
  height: 16px;
  width: 16px;
  border: 1px solid rgba(0, 0, 0, 0);
  margin: 2px;
  padding: 0;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  width: 18px;
  margin-top: -9px;
  position: absolute;
  top: 50%;
  right: 0;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=""]):before {
  content: attr(data-label);
}

.ql-snow .ql-picker.ql-header {
  width: 98px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label:before, .ql-snow .ql-picker.ql-header .ql-picker-item:before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before {
  content: "Heading 1";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before {
  content: "Heading 2";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before {
  content: "Heading 3";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before {
  content: "Heading 4";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before {
  content: "Heading 5";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before {
  content: "Heading 6";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before {
  font-size: 2em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before {
  font-size: 1.5em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before {
  font-size: 1.17em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before {
  font-size: 1em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before {
  font-size: .83em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before {
  font-size: .67em;
}

.ql-snow .ql-picker.ql-font {
  width: 108px;
}

.ql-snow .ql-picker.ql-font .ql-picker-label:before, .ql-snow .ql-picker.ql-font .ql-picker-item:before {
  content: "Sans Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]:before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]:before {
  content: "Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]:before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]:before {
  content: "Monospace";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]:before {
  font-family: Georgia, Times New Roman, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]:before {
  font-family: Monaco, Courier New, monospace;
}

.ql-snow .ql-picker.ql-size {
  width: 98px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label:before, .ql-snow .ql-picker.ql-size .ql-picker-item:before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]:before {
  content: "Small";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]:before {
  content: "Large";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]:before {
  content: "Huge";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]:before {
  font-size: 10px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]:before {
  font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]:before {
  font-size: 32px;
}

.ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background-color: #fff;
}

.ql-snow .ql-color-picker.ql-color .ql-picker-item {
  background-color: #000;
}

.ql-toolbar.ql-snow {
  box-sizing: border-box;
  border: 1px solid #ccc;
  padding: 8px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
}

.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid rgba(0, 0, 0, 0);
}

.ql-toolbar.ql-snow .ql-picker-options {
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: 0 2px 8px rgba(0, 0, 0, .2);
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label, .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #ccc;
}

.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected, .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
  border-color: #000;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0;
}

.ql-snow .ql-tooltip {
  color: #444;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 12px;
  box-shadow: 0 0 5px #ddd;
}

.ql-snow .ql-tooltip:before {
  content: "Visit URL:";
  margin-right: 8px;
  line-height: 26px;
}

.ql-snow .ql-tooltip input[type="text"] {
  height: 26px;
  width: 170px;
  border: 1px solid #ccc;
  margin: 0;
  padding: 3px 5px;
  font-size: 13px;
  display: none;
}

.ql-snow .ql-tooltip a.ql-preview {
  max-width: 200px;
  text-overflow: ellipsis;
  vertical-align: top;
  display: inline-block;
  overflow-x: hidden;
}

.ql-snow .ql-tooltip a.ql-action:after {
  content: "Edit";
  border-right: 1px solid #ccc;
  margin-left: 16px;
  padding-right: 8px;
}

.ql-snow .ql-tooltip a.ql-remove:before {
  content: "Remove";
  margin-left: 8px;
}

.ql-snow .ql-tooltip a {
  line-height: 26px;
}

.ql-snow .ql-tooltip.ql-editing a.ql-preview, .ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}

.ql-snow .ql-tooltip.ql-editing input[type="text"] {
  display: inline-block;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action:after {
  content: "Save";
  border-right: 0;
  padding-right: 0;
}

.ql-snow .ql-tooltip[data-mode="link"]:before {
  content: "Enter link:";
}

.ql-snow .ql-tooltip[data-mode="formula"]:before {
  content: "Enter formula:";
}

.ql-snow .ql-tooltip[data-mode="video"]:before {
  content: "Enter video:";
}

.ql-snow a {
  color: #06c;
}

.ql-container.ql-snow {
  border: 1px solid #ccc;
}

.CircularProgressbar {
  width: 100%;
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset .5s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  dominant-baseline: middle;
  text-anchor: middle;
  font-size: 20px;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: rgba(0, 0, 0, 0);
}

#main-grid {
  width: 100%;
  height: 100%;
  padding: 25px;
  display: grid;
  position: relative;
}

#main-content .tile-username > .ellipsis-tile-username {
  max-width: 150px;
}

#main-grid.meeting-main-with-sidebar .tile-username > .ellipsis-tile-username {
  max-width: 60px;
}

#main-grid.full-screen {
  z-index: 101;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#main-grid > main {
  grid-area: main;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

#main-grid > main > .main-content-inner {
  width: 100%;
  height: 100%;
}

#main-grid > main > .main-content-inner.screensharing {
  height: calc(100% - 50px);
}

#main-grid > #meeting-footer {
  grid-area: footer;
  margin-top: 10px;
  position: relative;
}

#meeting-footer > .left-footer {
  display: flex;
  position: absolute;
  top: 10px;
  left: 10px;
}

#meeting-footer > .left-footer button.controlbutton {
  box-shadow: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 3;
  border: none;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 16px;
  display: flex;
}

#meeting-footer > .left-footer button.controlbutto > svg {
  width: 24px;
}

#meeting-footer > .left-footer button.controlbutton:disabled {
  cursor: default;
  opacity: .3;
}

#main-grid > aside {
  grid-area: aside;
}

.participants-wrapper .participant {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  margin: 0;
  position: relative;
  overflow: hidden;
}

#main-grid.non-meeting {
  grid-template: "main aside" 1fr
  / 1fr 0;
}

#main-grid.meeting-full-main {
  grid-template: "main aside" 1fr
                 "footer footer" 70px
                 / 1fr 0;
}

#main-grid.meeting-full-sidebar {
  grid-template: "main aside" 1fr
                 "header header" 50px
                 "footer footer" 70px
                 / 0 1fr;
}

#main-grid.meeting-full-sidebar > aside {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

#main-grid.meeting-full-sidebar .participants-wrapper {
  height: 100%;
  width: 100%;
  grid-auto-flow: row;
  justify-items: center;
  gap: 10px;
  display: grid;
}

#main-grid.meeting-full-sidebar .participants-wrapper .participant {
  width: 100%;
  height: 100%;
}

#main-grid.meeting-full-sidebar .participants-wrapper {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: minmax(150px, 1fr);
}

#main-grid.meeting-main-with-sidebar > aside#participants-sidebar .participants-wrapper .participant .avatar-img-wrapper {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
}

.participant {
  position: relative;
}

.participant .avatar-img-wrapper {
  min-width: 100px;
  min-height: 100px;
  width: 100px;
  height: 100px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.participant .avatar-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.participant > video + .avatar-img-wrapper {
  z-index: -1;
}

@media screen and (min-width: 720px) {
  #main-grid.meeting-full-sidebar .participants-wrapper.p-1 {
    grid-template-columns: repeat(1, 1fr);
  }

  #main-grid.meeting-full-sidebar .participants-wrapper.p-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  #main-grid.meeting-full-sidebar .participants-wrapper.p-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  #main-grid.meeting-full-sidebar .participants-wrapper.p-4 {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
}

#main-grid.meeting-main-with-sidebar {
  grid-template: "main aside" 1fr
                 "footer footer" 70px
                 / 1fr 130px;
  padding-right: 0;
}

#main-grid.meeting-main-with-sidebar .participants-wrapper {
  grid-auto-flow: row;
  justify-items: right;
  gap: 10px;
  display: grid;
}

#participants-sidebar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: scroll;
}

#participants-sidebar::-webkit-scrollbar {
  display: none;
}

.participant {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  margin: 0;
  position: relative;
  overflow: hidden;
}

#main-grid main .participant.main-participant {
  width: 100%;
  height: 100%;
}

#main-grid main .participant.main-participant .presentation-label {
  text-shadow: 0 0 2px rgba(0, 0, 0, .5);
  color: #fff;
  font-weight: 400;
  position: absolute;
  top: 10px;
  left: 10px;
}

#main-grid #main-content .local-presenter-label, #main-grid.meeting-full-sidebar header.local-presenter-label {
  height: 40px;
  max-height: 40px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
  font-weight: 500;
  display: flex;
  position: relative;
}

#main-grid.meeting-full-sidebar header.local-presenter-label {
  grid-area: header;
  margin-bottom: 10px;
  padding: 0;
}

#main-grid #main-content .local-presenter-label-bg, #main-grid.meeting-full-sidebar header.local-presenter-label .local-presenter-label-bg {
  width: 100%;
  height: 100%;
  opacity: .5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#main-grid main .participant.main-participant .presentation-label .current-presenter, #participants-sidebar .participant.main-participant .presentation-label .current-presenter {
  font-weight: 500;
}

#participants-sidebar .participant.main-participant .presentation-label {
  text-shadow: 0 0 2px rgba(0, 0, 0, .5);
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 10px;
  left: 10px;
  overflow: hidden;
}

#main-grid .meeting-controls {
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

#main-grid .meeting-controls button {
  margin-left: 3px;
  margin-right: 3px;
}

#main-grid .meeting-controls button.controlbutton {
  box-shadow: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 3;
  border: none;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  display: flex;
}

#main-grid .meeting-controls button.controlbutton > svg {
  width: 24px;
}

#main-grid .meeting-controls button.controlbutton:disabled {
  cursor: default;
  opacity: .3;
}

.room-modal-backdrop {
  z-index: 100000000;
  background-color: rgba(34, 34, 34, .5);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.room-modal-backdrop .room-modal {
  width: 400px;
  max-width: 400px;
  max-height: 90vh;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  font-weight: 400;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.room-modal-backdrop .room-modal .header {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.room-modal .field-wrapper .label {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 500;
}

.room-modal .field-wrapper .select-custom {
  cursor: pointer;
  width: 100%;
  border: 1px solid;
  border-radius: 32px;
  padding: 8px 24px 8px 16px;
}

.room-modal .default-options {
  grid-gap: 10px;
  grid-auto-flow: column;
  justify-content: center;
  display: grid;
}

.room-modal .layout-options {
  align-items: center;
  padding: 20px 10px;
  display: flex;
}

.room-modal .layout-options > div:first-child {
  text-align: left;
  flex: 1;
}

.room-modal .default-options .thumb-container {
  font-size: 24px;
}

.room-modal .default-options .thumb-container img {
  height: 30px;
}

.room-modal .default-options .thumb-container {
  width: 80px;
  border-radius: 10px;
  margin-top: 20px;
}

.room-modal .default-options .thumb-container .bg-option-label {
  margin-top: 10px;
  font-size: 13px;
}

.modal-video-track-container {
  min-width: 320px;
  min-height: 230px;
  max-width: 320px;
  max-height: 230px;
  width: 320px;
  height: 230px;
  background-color: #000;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin: 20px auto 10px;
  display: flex;
}

.modal-video-track-container video {
  border-radius: 10px;
  margin: 0;
  position: relative;
}

.thumb-container h1 {
  height: 80px;
  width: 100%;
  cursor: pointer;
  border: 2px solid #000;
  justify-content: center;
  align-items: center;
  display: flex;
}

.thumb-container img {
  height: 90px;
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
}

.thumb-container > img {
  border: 2px solid;
}

.thumb-list {
  grid-gap: 10px;
  grid-template-columns: repeat(2, minmax(170px, 1fr));
  margin-top: 30px;
  display: grid;
}

#draggable-boundary {
  z-index: 4;
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#draggable-participant {
  width: 195px;
  height: 160px;
  opacity: 0;
  pointer-events: all;
  background-color: #333;
  border-radius: 10px;
  animation: fadein 1s ease-in forwards;
  overflow: hidden;
  box-shadow: -1px 0 35px -8px rgba(0, 0, 0, .75);
}

#draggable-participant .participant {
  width: 100% !important;
  height: 100% !important;
}

@media screen and (max-width: 767px) {
  #draggable-participant {
    width: 150px;
    height: 120px;
  }

  .room-modal-backdrop .room-modal {
    width: 350px;
  }

  .modal-video-track-container {
    min-width: 270px;
    width: 270px;
    max-width: 270px;
    max-height: 180px;
    height: 180px;
    min-height: 180px;
  }

  .thumb-list {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.network-outer {
  width: 2em;
  height: 2em;
  background: rgba(0, 0, 0, .5);
  justify-content: center;
  align-items: center;
  padding: .9em;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

.network-inner {
  align-items: flex-end;
  display: flex;
}

.network-inner div {
  width: 2px;
  margin-right: 1px;
}

.network-inner div:not(:last-child) {
  border-right: none;
}

.host-indicator {
  margin-right: 2px;
}

.floating-boundary {
  display: none;
}

.main-participant:hover .floating-boundary {
  z-index: 2;
  cursor: pointer;
  background: rgba(0, 0, 0, .3);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.main-participant:hover .floating-boundary button {
  color: #fff;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 24px;
}

.participant img {
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}



/*# sourceMappingURL=index.f9ed5348.css.map */
